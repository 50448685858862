<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t('orderMsgs.orders') }}
              </h4>
            </div>
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <div class="d-flex align-items-center mb-2">
                <div class="mm-search-bar col-sm-6 col-md-6 px-1">
                  <div class="searchbox">
                    <a class="search-link" href="#"><i class="fas fa-search"></i></a>
                    <input
                      type="text"
                      class="form-control search-input"
                      v-model="searchTerm"
                      :placeholder="`${$t('orderMsgs.searchOrders')}...`"
                      @input="onSearch"
                    />
                  </div>
                </div>
              </div>

              <b-table
                :fields="tableColumns"
                :items="allOrders"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
                @row-clicked="openOrderDetailsModal"
              >
                <template #cell(order_number)="data">
                  <strong>
                    {{ data.item.order_number }}
                  </strong>
                </template>

                <template #cell(customer__name)="data">
                  {{ data.item.customer.name }}
                </template>

                <template #cell(sheet_numbers)="data">
                  {{ truncateSheetNums(data.item.sheet_numbers) }}
                </template>

                <template #cell(bill)="data">
                  <b-skeleton-wrapper :loading="isCountLoading">
                    <template #loading>
                      <b-skeleton width="100%"></b-skeleton>
                    </template>

                    {{ $n(get(countByOrder, `${data.item.id}.count_bill`, 0), 'number', 'de-DE') }}
                  </b-skeleton-wrapper>
                </template>

                <template #cell(drawing_files)="data">
                  <b-skeleton-wrapper :loading="isCountLoading">
                    <template #loading>
                      <b-skeleton width="85%"></b-skeleton>
                    </template>

                    {{ $n(get(countByOrder, `${data.item.id}.count_drawing_file`, 0), 'number', 'de-DE') }}
                  </b-skeleton-wrapper>
                </template>
              </b-table>

              <div class="d-flex justify-content-end">
                <b-form-select
                  class="form-control form-control-b-select mr-2"
                  style="height: 38px; width: 5rem"
                  v-model="pageSize"
                  :options="perPageChoices"
                  @input="onPerPageChange"
                >
                  <template #first>
                    <b-form-select-option :value="-1" disabled>
                      {{ $t('orderMsgs.ordersPerPage') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <b-pagination
                  :value="currentPage"
                  :total-rows="totalOrders"
                  :per-page="pageSize || totalOrders"
                  @change="onPageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <order-detail-modal
      :showModal.sync="modals.showOrderDetailsModal"
      :orderId="modals.selectedOrderDetailsId"
      :orderNum="modals.selectedOrderNum"
      :selectedTabIndex="2"
      @close="hideOrderDetailsModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import OrderDetailModal from '../Orders/OrderDetail/OrderDetailModal.vue';
import { debounce, get, keyBy, truncate } from 'lodash';
import { USER_ROLES } from '../../common/constants';
import { formatDate } from '../../common/utils';

export default {
  name: 'OrderCalcList',

  components: {
    OrderDetailModal,
  },

  data() {
    return {
      allOrders: [],
      currentPage: 1,
      pageSize: 20,
      totalOrders: 0,

      customer: null,
      creator: null,
      status: null,
      deliveredDateRange: null,
      invoicedDateRange: null,

      countByOrder: {},

      isLoading: false,
      isCountLoading: false,
      searchTerm: '',
      ordering: null,
      USER_ROLES,

      modals: {
        selectedOrder: null,
        selectedOrderNum: null,
        showOrderDetailsModal: false,
        selectedOrderDetailsId: null,
      },

      perPageChoices: [
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 30, text: '30' },
        { value: 50, text: '50' },
        { value: null, text: 'All' },
      ],
    };
  },

  methods: {
    ...mapActions(['getAllOrders', 'getOrderBillandDrawingFileCount']),
    get,
    formatDate,
    openOrderDetailsModal(orderRow) {
      this.modals.selectedOrderDetailsId = orderRow.id;
      this.modals.selectedOrderNum = orderRow.order_number;
      this.modals.showOrderDetailsModal = true;
    },
    hideOrderDetailsModal() {
      this.modals.showOrderDetailsModal = false;
      this.fetchAndSetOrders(this.currentPage);
      this.modals.selectedOrderDetailsId = this.modals.selectedOrderNum = null;
    },
    async fetchCount() {
      this.isCountLoading = true;
      const count = (
        await this.getOrderBillandDrawingFileCount({
          order_ids: this.allOrders.map((order) => order.id),
        })
      ).data.stats;
      const countBillandDrawingFilesByOrder = keyBy(count, 'order');
      this.countByOrder = countBillandDrawingFilesByOrder;
      this.isCountLoading = false;
    },
    async fetchAndSetOrders(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllOrders({
        ...(this.pageSize && {
          limit: this.pageSize,
          offset: (pageNum - 1) * this.pageSize,
        }),
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.customer && { customer: this.customer }),
        ...(this.creator && { created_by: this.creator }),
        ...(this.status && { status: this.status }),
        ...(this.deliveredDateRange &&
          this.deliveredDateRange.some((element) => element !== null) && {
            delivered_date_range: this.deliveredDateRange.join(),
          }),
        ...(this.invoicedDateRange &&
          this.invoicedDateRange.some((element) => element !== null) && {
            invoiced_date_range: this.invoicedDateRange.join(),
          }),
        ...params,
      });

      const orders = this.pageSize ? response.data.results : response.data;

      this.allOrders = orders;
      this.currentPage = pageNum;
      this.totalOrders = this.pageSize ? response.data.count : get(orders, 'length', 0);
      this.isLoading = false;

      this.fetchCount();
    },

    onPerPageChange() {
      this.fetchAndSetOrders();
    },

    onPageChange(pageNum) {
      this.fetchAndSetOrders(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAndSetOrders();
    },

    onSearch() {
      this.debouncedSearchOrders(this);
    },

    debouncedSearchOrders: debounce((vm) => {
      vm.fetchAndSetOrders();
    }, 500),

    truncateSheetNums(sheetNum) {
      return truncate(sheetNum, { length: 30, separator: /[, ?+]/ });
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchAndSetOrders();
  },

  computed: {
    ...mapGetters(['getLoggedInUser', 'getCurrLanguage']),

    lang() {
      return this.getCurrLanguage;
    },

    tableColumns() {
      return [
        { key: 'order_number', label: this.$t('orderMsgs.orderNumShort'), tdClass: 'clickable-item', sortable: true },
        { key: 'customer__name', label: this.$t('customerMsgs.customer'), tdClass: 'clickable-item', sortable: true },
        { key: 'sheet_numbers', label: this.$t('orderMsgs.sheetNums'), tdClass: 'clickable-item', sortable: true },
        { key: 'bill', label: this.$t('billPaperMsgs.bills'), tdClass: 'clickable-item' },
        { key: 'drawing_files', label: this.$t('orderMsgs.drawingFiles'), tdClass: 'clickable-item' },
      ];
    },
  },
};
</script>

